import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.gestures.scrollBy
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableSharedFlow
import model.Screen
import model.UiDirection
import model.fromPath
import org.jetbrains.skiko.loadBytesFromPath
import se.sekvy.compose.markdown.MDDocument
import se.sekvy.compose.markdown.MarkdownParser
import se.sekvy.compose.markdown.NodeType
import se.sekvy.compose.markdown.ParserType
import theme.HomePageTheme
import theme.colorBackgroundDark
import ui.ArticleInfoBox
import ui.CircleAnimation
import ui.CircleAnimationState
import ui.CirclePlacement
import ui.ContactInfoBox
import ui.CornerAnimationState
import ui.CustomScaffold
import ui.InfoBox
import ui.Menu
import ui.rememberCircleAnimationState
import kotlin.time.Duration.Companion.seconds

const val hashSlashFix: Boolean = true

fun main() {
    val onSize = MutableSharedFlow<IntOffset>(extraBufferCapacity = 1, replay = 1)
    val onScroll = MutableSharedFlow<Double>(extraBufferCapacity = 1, replay = 1)
    val onHashChange = MutableSharedFlow<String>(extraBufferCapacity = 1, replay = 1)
    renderInComposeWindow(
        backgroundColor = colorBackgroundDark,
        onNewWindowSize = { width, height ->
            onSize.tryEmit(IntOffset(width, height))
        },
        onScroll = {
            onScroll.tryEmit(it)
        },
        onHashChange = {
            onHashChange.tryEmit(it)
        }
    ) {
        HomePageTheme {
            val uiDirection = onSize.collectAsState(IntOffset(0, 0)).toUIDirection()
            Content(
                uiDirection = uiDirection,
                onScroll = onScroll,
                onHashChange = onHashChange
            )
        }
    }
}

@Composable
fun Content(uiDirection: UiDirection, onScroll: MutableSharedFlow<Double>, onHashChange: MutableSharedFlow<String>) {
    var showContent by remember { mutableStateOf(false) }

    val circleAnimationState = rememberCircleAnimationState(CirclePlacement.CENTER, true)
    var circlePlacement by circleAnimationState.circlePlacement

    val alpha = animateFloatAsState(if (showContent) 1f else 0.01f, tween(800))

    var centerScreen by remember {
        mutableStateOf<Screen>(HomeScreen(""))
    }

    LaunchedEffect(Unit) {
        snapshotFlow { centerScreen }.collect {
            console.info(
                "centerScreen: setting hash to ${it.path}"
            )
            val newHash = if (hashSlashFix) it.path.replace("/", "|") else it.path
            if (newHash.isNotEmpty()) {
                window.location.hash = newHash.lowercase()
            }
        }
    }

    LaunchedEffect(Unit) {
        onHashChange.collect {
            val screen = fromPath(paths, if (hashSlashFix) it.replace("|", "/") else it)
            when (screen) {
                is HomeScreen -> {
                    showContent = true
                    circleAnimationState.animateInLines.value = false
                    circlePlacement = CirclePlacement.CENTER
                }
                null -> {}
                else -> {
                    showContent = true
                    circleAnimationState.animateInLines.value = false
                    circlePlacement = CirclePlacement.CORNER
                }
            }
            if (screen != null) centerScreen = screen
        }
    }

    CustomScaffold(
        uiDirection = uiDirection,
        startContent = {
            if (uiDirection == UiDirection.Horizontal) {
                MenuContent(
                    Modifier.alpha(alpha.value),
                    uiDirection,
                    circleAnimationState
                ) {
                    centerScreen = it
                }
            }
        },
        centerContent = {
            val scrollState = rememberScrollState()

            LaunchedEffect(Unit) {
                onScroll.collect {
                    scrollState.scrollBy(it.toFloat())
                }
            }

            Box(
                modifier = Modifier.matchParentSize().verticalScroll(scrollState),
                contentAlignment = Alignment.Center
            ) {
                AnimatedVisibility(
                    visible = centerScreen is HomeScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                    }
                )
                AnimatedVisibility(
                    visible = centerScreen is DevelopersScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                        InfoBox()
                    }
                )
                AnimatedVisibility(
                    visible = centerScreen is ArticlesScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                        val articles = listOf("markdown_compose.md", "publish_multiplatform_library.md")
                        Column(
                            verticalArrangement = Arrangement.spacedBy(16.dp),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            ArticleInfoBox(
                                "Rendering Markdown in Compose for Web Canvas",
                                articles[0]
                            ) {
                                centerScreen = ArticleScreen(
                                    path = "articles/${articles[0]}",
                                    articlePath = articles[0]
                                )
                            }
                            ArticleInfoBox(
                                "Link dump on publishing a multiplatform library",
                                articles[1]
                            ) {
                                centerScreen = ArticleScreen(
                                    path = "articles/${articles[1]}",
                                    articlePath = articles[1]
                                )
                            }
                        }
                    }
                )
                AnimatedVisibility(
                    visible = centerScreen is ArticleScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                        val articlePath = (centerScreen as? ArticleScreen)?.articlePath ?: ""
                        if (articlePath.isNotEmpty()) showArticle(articlePath, uiDirection)
                    }
                )
                AnimatedVisibility(
                    visible = centerScreen is ContactScreen,
                    enter = fadeIn(animationSpec = tween(1000)),
                    exit = fadeOut(),
                    content = {
                        Box(Modifier.fillMaxSize().padding(if (uiDirection == UiDirection.Vertical) 8.dp else 88.dp)) {
                            ContactInfoBox()
                        }
                    }
                )
            }
        },
        endContent = {
            if (uiDirection == UiDirection.Vertical) {
                MenuContent(
                    Modifier.alpha(alpha.value),
                    uiDirection,
                    circleAnimationState
                ) {
                    centerScreen = it
                }
            }
        },
        overlay = {
            if (circlePlacement == CirclePlacement.CORNER && uiDirection == UiDirection.Vertical) return@CustomScaffold
            CircleAnimation(
                cornerPaddingX = if (uiDirection == UiDirection.Vertical) 8.dp else 88.dp,
                cornerPaddingY = if (uiDirection == UiDirection.Vertical) 8.dp else 8.dp,
                onCompleting = {
                    showContent = true
                },
                onComplete = {},
                radius = 180.dp,
                content = { cornerAnimationState ->
                    Column(
                        modifier = Modifier.fillMaxSize().alpha(alpha.value),
                        verticalArrangement = Arrangement.Center
                    ) {
                        when (cornerAnimationState) {
                            CornerAnimationState.IN_CORNER -> {
                                Text(
                                    modifier = Modifier.fillMaxWidth(),
                                    textAlign = TextAlign.Center,
                                    text = "Sekvy",
                                    fontSize = 10.sp,
                                    fontStyle = FontStyle.Normal
                                )
                            }

                            CornerAnimationState.IN_CENTER_END,
                            CornerAnimationState.IN_CENTER -> {
                                Text(
                                    modifier = Modifier.fillMaxWidth(),
                                    textAlign = TextAlign.Center,
                                    text = "Sekvy AB",
                                    fontSize = 20.sp,
                                    fontStyle = FontStyle.Normal
                                )
                                Text(
                                    modifier = Modifier.fillMaxWidth().padding(top = 16.dp),
                                    textAlign = TextAlign.Center,
                                    text = "Android Developers",
                                    fontSize = 20.sp,
                                    fontStyle = FontStyle.Normal
                                )
                                Text(
                                    modifier = Modifier.fillMaxWidth().padding(top = 16.dp),
                                    textAlign = TextAlign.Center,
                                    text = "Kotlin Multiplatform aficionados",
                                    fontSize = 20.sp,
                                    fontStyle = FontStyle.Normal
                                )
                            }

                            else -> {}
                        }
                    }
                },
                lineDuration = 1.seconds,
                circleRepeatableDuration = 10.seconds,
                circleAnimationState = circleAnimationState
            )
        }
    )
}

@Composable
fun MenuContent(
    modifier: Modifier,
    uiDirection: UiDirection,
    state: CircleAnimationState,
    onSelected: (Screen) -> Unit
) {
    Box(modifier) {
        Menu(
            direction = uiDirection,
            onHomeClick = {
                state.circlePlacement.value = CirclePlacement.CENTER
                onSelected(HomeScreen(HomePath().pathPattern))
            },
            onDevelopersClick = {
                state.circlePlacement.value = CirclePlacement.CORNER
                onSelected(DevelopersScreen(DevelopersPath().pathPattern))
            },
            onArticlesClick = {
                state.circlePlacement.value = CirclePlacement.CORNER
                onSelected(ArticlesScreen(ArticlesPath().pathPattern))
            },
            onContactClick = {
                state.circlePlacement.value = CirclePlacement.CORNER
                onSelected(ContactScreen(ContactPath().pathPattern))
            }
        )
    }
}

@Composable
fun showArticle(path: String, uiDirection: UiDirection) {
    var nodeType: NodeType? by remember { mutableStateOf(null) }

    LaunchedEffect(path) {
        val bytes = loadBytesFromPath(path)
        nodeType = MarkdownParser(ParserType.CommonMark).parse(bytes.decodeToString())
    }

    nodeType?.let {
        val padding = if (uiDirection == UiDirection.Vertical) {
            24.dp
        } else {
            88.dp
        }
        Box(modifier = Modifier.padding(start = padding, end = padding, top = 24.dp, bottom = 24.dp)) {
            Column {
                MDDocument(it) {
                    loadBitmap(it)
                }
            }
        }
    }
}
