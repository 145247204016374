package theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material.MaterialTheme
import androidx.compose.material.darkColors
import androidx.compose.material.lightColors
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color

private val DarkColorPalette = darkColors(
    primary = colorPrimaryDark,
//    primaryVariant = colorPrimaryDark,
//    secondary = colorSecondary,
//    secondaryVariant = colorSecondaryDark,
    background = colorBackgroundDark,
    surface = colorBackgroundDark,
//    error = colorError,
//    onError = Color.White,
    onPrimary = Color.White,
    onSecondary = Color.White,
    onBackground = Color.White,
    onSurface = Color.White
)

private val LightColorPalette = lightColors(
    primary = colorPrimary,
//    primaryVariant = colorPrimaryDark,
//    secondary = colorSecondary,
//    secondaryVariant = colorSecondaryDark,
    background = colorBackground,
//    surface = colorBackground,
//    error = colorError,
//    onError = Color.White,
    onPrimary = colorOnSurface,
    onSecondary = colorOnSurface,
    onBackground = colorOnSurface,
    onSurface = colorOnSurface
)

@Composable
fun HomePageTheme(darkTheme: Boolean = isSystemInDarkTheme(), content: @Composable () -> Unit) {
    // FIXME: isSystemInDarkTheme is not implemented in Kotlin/JS, hardcoding theme switch for now.
    val colors = if (true) {
        DarkColorPalette
    } else {
        LightColorPalette
    }

    MaterialTheme(
        colors = colors,
        // typography = Typography,
        // shapes = Shapes,
        content = content
    )
}
