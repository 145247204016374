package theme

import androidx.compose.ui.graphics.Color

val colorPrimary = Color(0xFF3395FF)
val colorPrimaryDark = Color(0xFFc0c0c0)

val colorSecondary = Color.White
val colorSecondaryDark = Color.White

val colorBackground = Color(0xFFE6E6FF)
val colorBackgroundDark = Color(0xFF171923)

val colorError = Color(0x4Dff0000)

val colorOnSurface = Color(0xFF9999FF)
val colorOnSurfaceDark = Color(0xFF9999FF)
