package theme.iconPacks.menuiconpack

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import theme.iconPacks.MenuIcons

public val MenuIcons.Contact: ImageVector
    get() {
        if (_contact != null) {
            return _contact!!
        }
        _contact = Builder(
            name = "Contact",
            defaultWidth = 24.0.dp,
            defaultHeight = 24.0.dp,
            viewportWidth = 24.0f,
            viewportHeight = 24.0f
        ).apply {
            path(
                fill = SolidColor(Color(0x00000000)),
                stroke = SolidColor(Color(0xFF000000)),
                strokeLineWidth = 2.0f,
                strokeLineCap = Butt,
                strokeLineJoin = Miter,
                strokeLineMiter = 4.0f,
                pathFillType = NonZero
            ) {
                moveTo(1.0f, 2.0f)
                lineTo(22.0f, 2.0f)
                lineTo(22.0f, 18.0f)
                lineTo(14.0f, 18.0f)
                lineTo(6.0f, 22.0f)
                lineTo(6.0f, 18.0f)
                lineTo(1.0f, 18.0f)
                lineTo(1.0f, 2.0f)
                close()
                moveTo(6.0f, 10.0f)
                lineTo(7.0f, 10.0f)
                lineTo(7.0f, 11.0f)
                lineTo(6.0f, 11.0f)
                lineTo(6.0f, 10.0f)
                close()
                moveTo(11.0f, 10.0f)
                lineTo(12.0f, 10.0f)
                lineTo(12.0f, 11.0f)
                lineTo(11.0f, 11.0f)
                lineTo(11.0f, 10.0f)
                close()
                moveTo(16.0f, 10.0f)
                lineTo(17.0f, 10.0f)
                lineTo(17.0f, 11.0f)
                lineTo(16.0f, 11.0f)
                lineTo(16.0f, 10.0f)
                close()
            }
        }
            .build()
        return _contact!!
    }

private var _contact: ImageVector? = null
