package theme.iconPacks.menuiconpack

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeCap.Companion.Round
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import theme.iconPacks.MenuIcons

public val MenuIcons.Person: ImageVector
    get() {
        if (_person != null) {
            return _person!!
        }
        _person = Builder(
            name = "Person",
            defaultWidth = 24.0.dp,
            defaultHeight = 24.0.dp,
            viewportWidth = 24.0f,
            viewportHeight = 24.0f
        ).apply {
            path(
                fill = SolidColor(Color(0x00000000)),
                stroke = SolidColor(Color(0xFF000000)),
                strokeLineWidth = 2.0f,
                strokeLineCap = Butt,
                strokeLineJoin = Miter,
                strokeLineMiter = 4.0f,
                pathFillType = NonZero
            ) {
                moveTo(12.0f, 7.0f)
                moveToRelative(-5.0f, 0.0f)
                arcToRelative(5.0f, 5.0f, 0.0f, true, true, 10.0f, 0.0f)
                arcToRelative(5.0f, 5.0f, 0.0f, true, true, -10.0f, 0.0f)
            }
            path(
                fill = SolidColor(Color(0x00000000)),
                stroke = SolidColor(Color(0xFF000000)),
                strokeLineWidth = 2.0f,
                strokeLineCap = Round,
                strokeLineJoin =
                StrokeJoin.Companion.Round,
                strokeLineMiter = 4.0f,
                pathFillType = NonZero
            ) {
                moveTo(17.0f, 14.0f)
                horizontalLineTo(17.3517f)
                curveTo(18.8646f, 14.0f, 20.1408f, 15.1266f, 20.3285f, 16.6279f)
                lineTo(20.719f, 19.7519f)
                curveTo(20.8682f, 20.9456f, 19.9374f, 22.0f, 18.7344f, 22.0f)
                horizontalLineTo(5.2656f)
                curveTo(4.0626f, 22.0f, 3.1318f, 20.9456f, 3.281f, 19.7519f)
                lineTo(3.6715f, 16.6279f)
                curveTo(3.8592f, 15.1266f, 5.1354f, 14.0f, 6.6483f, 14.0f)
                horizontalLineTo(7.0f)
            }
        }
            .build()
        return _person!!
    }

private var _person: ImageVector? = null
