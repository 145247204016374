package ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Surface
import androidx.compose.material.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import model.UiDirection
import theme.iconPacks.MenuIcons
import theme.iconPacks.menuiconpack.Article
import theme.iconPacks.menuiconpack.Contact
import theme.iconPacks.menuiconpack.Home
import theme.iconPacks.menuiconpack.Person

@Composable
fun BoxScope.Menu(
    direction: UiDirection = UiDirection.Vertical,
    onHomeClick: () -> Unit = {},
    onDevelopersClick: () -> Unit = {},
    onArticlesClick: () -> Unit = {},
    onContactClick: () -> Unit = {}

) {
    val modifier = if (direction == UiDirection.Vertical) {
        Modifier.height(80.dp)
            .align(Alignment.TopStart).fillMaxWidth()
    } else {
        Modifier.width(80.dp)
            .align(Alignment.TopStart).fillMaxHeight()
    }

    Surface(
        modifier = modifier,
        elevation = 2.dp
    ) {
        if (direction == UiDirection.Vertical) {
            Row(
                modifier = Modifier.matchParentSize()
            ) {
                Buttons(
                    Modifier.fillMaxSize().weight(1f),
                    onHomeClick = onHomeClick,
                    onDevelopersClick = onDevelopersClick,
                    onArticlesClick = onArticlesClick,
                    onContactClick = onContactClick
                )
            }
        } else {
            Column(
                modifier = Modifier.matchParentSize(),
                verticalArrangement = Arrangement.SpaceEvenly,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Buttons(
                    Modifier.fillMaxSize().weight(1f),
                    onHomeClick = onHomeClick,
                    onDevelopersClick = onDevelopersClick,
                    onArticlesClick = onArticlesClick,
                    onContactClick = onContactClick
                )
            }
        }
    }
}

@Composable
private fun Buttons(
    modifier: Modifier,
    onHomeClick: () -> Unit = {},
    onDevelopersClick: () -> Unit = {},
    onArticlesClick: () -> Unit = {},
    onContactClick: () -> Unit = {}
) {
    TextButton(
        modifier = modifier,
        onClick = onHomeClick
    ) {
        Image(
            modifier = Modifier.width(32.dp),
            imageVector = MenuIcons.Home,
            contentDescription = "",
            contentScale = ContentScale.FillWidth,
            colorFilter = ColorFilter.tint(MaterialTheme.colors.primary)
        )
    }

    TextButton(
        modifier = modifier,
        onClick = onDevelopersClick
    ) {
        Image(
            modifier = Modifier.width(32.dp),
            imageVector = MenuIcons.Person,
            contentDescription = "",
            contentScale = ContentScale.FillWidth,
            colorFilter = ColorFilter.tint(MaterialTheme.colors.primary)
        )
    }

    TextButton(
        modifier = modifier,
        onClick = onArticlesClick
    ) {
        Image(
            modifier = Modifier.width(32.dp),
            imageVector = MenuIcons.Article,
            contentDescription = "",
            contentScale = ContentScale.FillWidth,
            colorFilter = ColorFilter.tint(MaterialTheme.colors.primary)
        )
    }

    TextButton(
        modifier = modifier,
        onClick = onContactClick
    ) {
        Image(
            modifier = Modifier.width(32.dp),
            imageVector = MenuIcons.Contact,
            contentDescription = "",
            contentScale = ContentScale.FillWidth,
            colorFilter = ColorFilter.tint(MaterialTheme.colors.primary)
        )
    }
}
