package theme.iconPacks.menuiconpack

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import theme.iconPacks.MenuIcons

public val MenuIcons.Home: ImageVector
    get() {
        if (_home != null) {
            return _home!!
        }
        _home = Builder(
            name = "Home",
            defaultWidth = 176.532.dp,
            defaultHeight = 176.532.dp,
            viewportWidth = 176.532f,
            viewportHeight = 176.532f
        ).apply {
            path(
                fill = SolidColor(Color(0xFF1D1D1B)),
                stroke = null,
                strokeLineWidth = 0.0f,
                strokeLineCap = Butt,
                strokeLineJoin = Miter,
                strokeLineMiter = 4.0f,
                pathFillType = NonZero
            ) {
                moveTo(152.928f, 85.912f)
                lineToRelative(-59.89f, -49.406f)
                curveToRelative(-2.771f, -2.287f, -6.773f, -2.287f, -9.545f, 0.0f)
                lineToRelative(-59.89f, 49.406f)
                curveToRelative(-1.728f, 1.425f, -2.728f, 3.546f, -2.728f, 5.785f)
                verticalLineToRelative(74.544f)
                curveToRelative(0.0f, 4.143f, 3.357f, 7.5f, 7.5f, 7.5f)
                horizontalLineToRelative(119.779f)
                curveToRelative(4.143f, 0.0f, 7.5f, -3.357f, 7.5f, -7.5f)
                verticalLineTo(91.697f)
                curveTo(155.656f, 89.458f, 154.656f, 87.337f, 152.928f, 85.912f)
                close()
                moveTo(96.196f, 158.741f)
                horizontalLineTo(80.336f)
                verticalLineToRelative(-41.524f)
                horizontalLineToRelative(15.859f)
                verticalLineTo(158.741f)
                close()
                moveTo(140.656f, 158.741f)
                horizontalLineToRelative(-29.46f)
                verticalLineToRelative(-49.024f)
                curveToRelative(0.0f, -4.143f, -3.357f, -7.5f, -7.5f, -7.5f)
                horizontalLineTo(72.836f)
                curveToRelative(-4.143f, 0.0f, -7.5f, 3.357f, -7.5f, 7.5f)
                verticalLineToRelative(49.024f)
                horizontalLineToRelative(-29.46f)
                verticalLineTo(95.233f)
                lineToRelative(52.39f, -43.219f)
                lineToRelative(52.39f, 43.219f)
                verticalLineTo(158.741f)
                close()
            }
            path(
                fill = SolidColor(Color(0xFF1D1D1B)),
                stroke = null,
                strokeLineWidth = 0.0f,
                strokeLineCap = Butt,
                strokeLineJoin = Miter,
                strokeLineMiter = 4.0f,
                pathFillType = NonZero
            ) {
                moveTo(173.72f, 70.866f)
                curveToRelative(-16.706f, -13.382f, -32.458f, -26.178f, -49.561f, -40.261f)
                curveToRelative(-5.109f, -4.591f, -10.479f, -8.938f, -15.675f, -13.144f)
                curveToRelative(-5.087f, -4.118f, -10.348f, -8.377f, -15.201f, -12.745f)
                curveToRelative(-2.854f, -2.568f, -7.182f, -2.568f, -10.035f, 0.0f)
                curveToRelative(-4.854f, 4.368f, -10.114f, 8.627f, -15.201f, 12.745f)
                curveToRelative(-5.195f, 4.205f, -10.565f, 8.553f, -15.675f, 13.144f)
                curveTo(35.27f, 44.689f, 19.518f, 57.484f, 2.812f, 70.866f)
                curveToRelative(-3.233f, 2.589f, -3.755f, 7.31f, -1.165f, 10.542f)
                curveToRelative(2.589f, 3.232f, 7.311f, 3.755f, 10.542f, 1.165f)
                curveTo(28.991f, 69.116f, 44.829f, 56.249f, 62.034f, 42.08f)
                curveToRelative(0.085f, -0.069f, 0.168f, -0.141f, 0.25f, -0.215f)
                curveToRelative(4.854f, -4.368f, 10.114f, -8.627f, 15.201f, -12.745f)
                curveToRelative(3.559f, -2.88f, 7.199f, -5.827f, 10.781f, -8.873f)
                curveToRelative(3.582f, 3.046f, 7.223f, 5.993f, 10.781f, 8.873f)
                curveToRelative(5.087f, 4.118f, 10.348f, 8.377f, 15.201f, 12.745f)
                curveToRelative(0.082f, 0.074f, 0.165f, 0.146f, 0.25f, 0.215f)
                curveToRelative(17.204f, 14.169f, 33.043f, 27.036f, 49.845f, 40.493f)
                curveToRelative(1.384f, 1.108f, 3.039f, 1.646f, 4.684f, 1.646f)
                curveToRelative(2.198f, 0.0f, 4.377f, -0.962f, 5.858f, -2.812f)
                curveTo(177.475f, 78.176f, 176.953f, 73.455f, 173.72f, 70.866f)
                close()
            }
        }
            .build()
        return _home!!
    }

private var _home: ImageVector? = null
