import model.Path
import model.Screen

class HomeScreen(override val path: String) : Screen

class DevelopersScreen(override val path: String) : Screen

class ArticlesScreen(
    override val path: String
) : Screen

class ArticleScreen(
    override val path: String,
    val articlePath: String = ""
) : Screen

class ContactScreen(
    override val path: String
) : Screen

class HomePath : Path<HomeScreen> {
    override val pathPattern: String = "home"

    override fun complete(path: String) = HomeScreen(path)
}

class DevelopersPath : Path<DevelopersScreen> {
    override val pathPattern: String = "developers"

    override fun complete(path: String) = DevelopersScreen(path)
}

class ArticlesPath : Path<ArticlesScreen> {
    override val pathPattern: String = "articles"

    override fun complete(path: String) = ArticlesScreen(path)
}

class ArticlePath : Path<ArticleScreen> {
    override val pathPattern: String = "articles/$"

    override fun complete(path: String) = ArticleScreen(path, path.split("/").last())
}

class ContactPath : Path<ContactScreen> {
    override val pathPattern: String = "contact"

    override fun complete(path: String) = ContactScreen(path)
}

val paths = listOf(
    HomePath(),
    DevelopersPath(),
    ArticlesPath(),
    ArticlePath(),
    ContactPath()
)
