package ui
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.FastOutSlowInEasing
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.BoxWithConstraintsScope
import androidx.compose.foundation.layout.absoluteOffset
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.drawscope.DrawStyle
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import pxToDp
import showBorder
import toPx
import ui.CornerAnimationState.IN_CENTER
import ui.CornerAnimationState.IN_CENTER_END
import ui.CornerAnimationState.IN_CORNER
import ui.CornerAnimationState.NONE
import ui.CornerAnimationState.TO_CENTER
import ui.CornerAnimationState.TO_CENTER_END
import ui.CornerAnimationState.TO_CORNER
import kotlin.time.Duration

private data class LineAttribute(
    val color: Color = Color.Black,
    val strokeWidth: Float = 1f
)

enum class CornerAnimationState {
    NONE, TO_CORNER, TO_CENTER, IN_CENTER, IN_CORNER, TO_CENTER_END, IN_CENTER_END
}

enum class CirclePlacement {
    CORNER, CENTER, CENTER_END
}

data class CircleAnimationState(
    val animateInLines: MutableState<Boolean> = mutableStateOf(true),
    val circlePlacement: MutableState<CirclePlacement> = mutableStateOf(CirclePlacement.CENTER)
)

@Composable
fun rememberCircleAnimationState(circlePlacement: CirclePlacement = CirclePlacement.CENTER, animateInLines: Boolean = false) =
    remember(circlePlacement) {
        CircleAnimationState().apply {
            this.animateInLines.value = animateInLines
            this.circlePlacement.value = circlePlacement
        }
    }

/**
 * Draw animations within a [BoxWithConstraintsScope].
 *
 * The resulting animation is a circle in center of the box. Use [content] to
 * add content within the circle.
 */
@Composable
fun BoxWithConstraintsScope.CircleAnimation(
    radius: Dp,
    lineDuration: Duration,
    circleRepeatableDuration: Duration,
    onCompleting: () -> Unit,
    onComplete: () -> Unit,
    cornerPaddingX: Dp,
    cornerPaddingY: Dp,
    circleAnimationState: CircleAnimationState,
    content: @Composable BoxScope.(CornerAnimationState) -> Unit
) {
    val maxWidth = this.maxWidth.toPx()
    val maxHeight = this.maxHeight.toPx()
    val radiusPx = radius.toPx()
    val minRadiusPx = 30.dp.toPx()
    val cornerPadding = Offset(cornerPaddingX.toPx(), cornerPaddingY.toPx())
    val duration = lineDuration.inWholeMilliseconds.toInt()
    val circlePlacement by circleAnimationState.circlePlacement
    var showLines by circleAnimationState.animateInLines
    val radiusAnimatable = remember { Animatable(if (circlePlacement == CirclePlacement.CORNER) minRadiusPx else radiusPx) }

    val offset = remember(maxWidth, maxHeight) {
        when (circlePlacement) {
            CirclePlacement.CORNER -> cornerPadding
            CirclePlacement.CENTER -> Offset(maxWidth / 2f - radiusPx, maxHeight / 2f - radiusPx)
            CirclePlacement.CENTER_END -> Offset(maxWidth - radiusPx * 2, maxHeight / 2f - radiusPx)
        }
    }

    val topLeftX = remember(maxWidth, maxHeight) { Animatable(offset.x) }
    val topLeftY = remember(maxWidth, maxHeight) { Animatable(offset.y) }
    var cornerAnimationState by remember { mutableStateOf(NONE) }

    val lineColor = MaterialTheme.colors.primary
    val lineAttributes = remember {
        arrayOf(
            LineAttribute(strokeWidth = 1f, color = lineColor.copy(alpha = 0.4f)),
            LineAttribute(strokeWidth = 2f, color = lineColor.copy(alpha = 0.6f)),
            LineAttribute(strokeWidth = 3f, color = lineColor.copy(alpha = 0.8f)),
            LineAttribute(strokeWidth = 4f, color = lineColor.copy(alpha = 1f))
        )
    }

    LaunchedEffect(circlePlacement) {
        val newState = when (circlePlacement) {
            CirclePlacement.CORNER -> IN_CORNER
            CirclePlacement.CENTER -> IN_CENTER
            CirclePlacement.CENTER_END -> IN_CENTER_END
        }

        val wasState = if (cornerAnimationState == NONE) {
            cornerAnimationState = newState
            newState
        } else {
            cornerAnimationState
        }

        if (wasState != newState) {
            val toOffset = when (circlePlacement) {
                CirclePlacement.CORNER -> cornerPadding
                CirclePlacement.CENTER -> Offset(maxWidth / 2f - radiusPx, maxHeight / 2f - radiusPx)
                CirclePlacement.CENTER_END -> Offset(maxWidth - radiusPx * 2, maxHeight / 2f - radiusPx)
            }
            val toRadius = when (circlePlacement) {
                CirclePlacement.CORNER -> minRadiusPx
                CirclePlacement.CENTER -> radiusPx
                CirclePlacement.CENTER_END -> radiusPx
            }
            cornerAnimationState = when (circlePlacement) {
                CirclePlacement.CORNER -> TO_CORNER
                CirclePlacement.CENTER -> TO_CENTER
                CirclePlacement.CENTER_END -> TO_CENTER_END
            }

            val job1 = launch {
                topLeftX.animateTo(
                    toOffset.x,
                    animationSpec = tween(durationMillis = duration / 2, easing = FastOutSlowInEasing)
                )
            }
            val job2 = launch {
                topLeftY.animateTo(
                    toOffset.y,
                    animationSpec = tween(durationMillis = duration / 2, easing = FastOutSlowInEasing)
                )
            }
            val job3 = launch {
                radiusAnimatable.animateTo(
                    toRadius,
                    animationSpec = tween(durationMillis = duration / 2, easing = FastOutSlowInEasing)
                )
            }
            listOf(job1, job2, job3).joinAll()
            cornerAnimationState = when (circlePlacement) {
                CirclePlacement.CORNER -> IN_CORNER
                CirclePlacement.CENTER -> IN_CENTER
                CirclePlacement.CENTER_END -> IN_CENTER_END
            }
        }
    }

    val boxSize = (radiusAnimatable.value * 2).pxToDp()
    Box(
        Modifier.size(boxSize).align(Alignment.TopStart).absoluteOffset {
            IntOffset(topLeftX.value.toInt(), topLeftY.value.toInt())
        }.showBorder(),
        contentAlignment = Alignment.Center
    ) {
        content(cornerAnimationState)
    }

    val animateFirstScene = remember {
        mutableStateListOf(
            Triple(
                Animatable(0f),
                maxWidth / 2f,
                tween<Float>(durationMillis = duration, easing = LinearEasing)
            ), // 0, end of line
            Triple(
                Animatable(0f),
                maxHeight / 2f,
                tween(durationMillis = duration, easing = LinearEasing)
            ), // 1, end height of line
            Triple(
                Animatable(0f),
                1f,
                tween(durationMillis = duration, easing = LinearEasing)
            ) // 2, line stroke width
        )
    }

    val animateSecondScene = remember {
        mutableStateListOf(
            Triple(
                Animatable(0f),
                maxWidth / 2f,
                tween<Float>(durationMillis = duration, easing = LinearEasing)
            ), // 0, start of line
            Triple(
                Animatable(0f),
                maxHeight / 2f,
                tween<Float>(durationMillis = duration, easing = LinearEasing)
            ), // 1, start height of line
            Triple(
                Animatable(0f),
                90f,
                tween<Float>(durationMillis = duration, easing = LinearEasing)
            )
            // 2, angle
        )
    }

    val animateThirdScene = remember {
        mutableStateListOf(
            Triple(
                Animatable(0f),
                360f,
                infiniteRepeatable(
                    animation = tween<Float>(
                        circleRepeatableDuration.inWholeMilliseconds.toInt(),
                        easing = LinearEasing
                    ),
                    repeatMode = RepeatMode.Restart
                )
            ) // 0, repeatable angle
        )
    }

    LaunchedEffect(Unit) {
        if (showLines) {
            animateFirstScene.map {
                launch {
                    it.first.animateTo(
                        it.second,
                        animationSpec = it.third
                    )
                }
            }.joinAll()

            withContext(Dispatchers.Main) {
                onCompleting()
            }

            animateSecondScene.map {
                launch {
                    it.first.animateTo(
                        it.second,
                        animationSpec = it.third
                    )
                }
            }.joinAll()
            showLines = false
        }

        withContext(Dispatchers.Main) {
            onComplete()
        }

        animateThirdScene.map {
            launch {
                it.first.animateTo(
                    it.second,
                    animationSpec = it.third
                )
            }
        }.joinAll()
    }

    val target = animateFirstScene[0].first
    val targetHeight = animateFirstScene[1].first
    val strokeFloat = animateFirstScene[2].first

    val startTarget = animateSecondScene[0].first
    val startTargetHeight = animateSecondScene[1].first
    val angle = animateSecondScene[2].first

    val repeatableAngle = animateThirdScene[0].first

    if (showLines) {
        DrawSingleLine(
            startX = maxWidth / 2f + radiusPx,
            endX = maxWidth / 2f + radiusPx,
            startY = startTargetHeight.value,
            endY = targetHeight.value,
            lineColor = lineAttributes[0].color,
            stroke = Stroke(strokeFloat.value * lineAttributes[0].strokeWidth)
        )
        DrawSingleLine(
            startX = maxWidth - startTarget.value,
            endX = maxWidth - target.value,
            startY = maxHeight / 2f + radiusPx,
            endY = maxHeight / 2f + radiusPx,
            lineColor = lineAttributes[1].color,
            stroke = Stroke(strokeFloat.value * lineAttributes[1].strokeWidth)
        )
        DrawSingleLine(
            startX = maxWidth / 2f - radiusPx,
            endX = maxWidth / 2f - radiusPx,
            startY = maxHeight - startTargetHeight.value,
            endY = maxHeight - targetHeight.value,
            lineColor = lineAttributes[2].color,
            stroke = Stroke(strokeFloat.value * lineAttributes[2].strokeWidth)
        )
        DrawSingleLine(
            startX = startTarget.value,
            endX = target.value,
            startY = maxHeight / 2f - radiusPx,
            endY = maxHeight / 2f - radiusPx,
            lineColor = lineAttributes[3].color,
            stroke = Stroke(strokeFloat.value * lineAttributes[3].strokeWidth)
        )

        for (x in 0 until 4) {
            DrawCircle(
                color = lineAttributes[x].color,
                startAngle = x * 90f + 0.4f, // TODO: + 0.4f fixes an overlap of the arc and incoming line
                sweepAngle = angle.value,
                topLeftX = maxWidth / 2f - radiusPx,
                topLeftY = maxHeight / 2f - radiusPx,
                radius = radiusPx,
                style = Stroke(lineAttributes[x].strokeWidth)
            )
        }
    } else {
        for (x in 0 until 4) {
            DrawCircle(
                color = lineAttributes[x].color,
                startAngle = repeatableAngle.value + x * 360 / 4,
                sweepAngle = 360f / 4,
                topLeftX = topLeftX.value,
                topLeftY = topLeftY.value,
                radius = radiusAnimatable.value,
                style = Stroke(lineAttributes[x].strokeWidth)
            )
        }
    }
}

@Composable
fun DrawCircle(
    color: Color,
    startAngle: Float,
    sweepAngle: Float,
    topLeftX: Float,
    topLeftY: Float,
    radius: Float,
    style: DrawStyle = Stroke(1.0f)
) {
    Canvas(
        modifier = Modifier
            .wrapContentSize()
    ) {
        drawArc(
            color = color,
            startAngle = startAngle,
            sweepAngle = sweepAngle,
            useCenter = false,
            topLeft = Offset(topLeftX, topLeftY),
            size = Size(radius * 2, radius * 2),
            style = style
        )
    }
}

@Composable
fun DrawSingleLine(
    startX: Float,
    startY: Float,
    endX: Float,
    endY: Float,
    lineColor: Color,
    stroke: Stroke = Stroke(1f)
) {
    Canvas(
        modifier = Modifier
            .wrapContentSize()
    ) {
        drawLine(
            start = Offset(startX, startY),
            end = Offset(endX, endY),
            color = lineColor,
            strokeWidth = stroke.width,
            cap = StrokeCap.Round
        )
    }
}
